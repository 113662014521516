<template>
  <div class="lang-switcher" :class="{ 'lang-switcher_open': isOpened }">
    <button
      type="button"
      class="lang-switcher__btn"
      @click="changeSwitcherState()"
    >
      <span class="ic-earch lang-icon"></span>
      <span>{{ currentLanguage.code }}</span>
    </button>
    <div class="lang-switcher__dropdown" v-if="isOpened">
      <span
        class="lang-switcher__item"
        v-for="lang in listLanguages"
        @click="changeLocale(lang.code)"
        :key="lang.code"
      >
        <span>{{ lang.code }}</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "LanguageSwitcher",
  data: function () {
    return {
      isOpened: false,
      languages: [
        { code: "de", src: require("@/assets/global/flags/de.svg") },
        { code: "en", src: require("@/assets/global/flags/en.svg") },
        { code: "fr", src: require("@/assets/global/flags/fr.svg") },
      ],
    };
  },
  mounted() {
    document.addEventListener("click", this.clickHandler);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickHandler);
  },
  methods: {
    clickHandler(e) {
      if (!this.$el.contains(e.target)) {
        this.isOpened = false;
      }
    },
    changeSwitcherState() {
      this.isOpened = !this.isOpened;
    },
    changeLocale(locale) {
      this.$i18n.locale = locale;
      this.$router.replace(this.$switchLocalePath(locale));
    },
  },
  computed: {
    currentLanguage() {
      return this.languages.find((item) => item.code === this.$i18n.locale);
    },
    listLanguages() {
      return this.languages.filter(
        (item) => item.code !== this.currentLanguage.code
      );
    },
  },
};
</script>

<style scoped lang="scss">
.lang-switcher {
  position: relative;
  &.lang-switcher_open {
    .lang-switcher__btn {
    }
  }
}
.lang-switcher__btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 9px 10px;
  border: 1px solid $gray;
  border-radius: 2px;
  background: transparent;
  gap: 10px;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: $white;
  width: 70px;

  .lang-icon {
    font-size: 17px;
    line-height: 1;
  }

  &:hover {
    background: $white;
    color: $dark;
  }
}
.lang-switcher__dropdown {
  position: absolute;
  top: 50px;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  width: 100%;
  background: $secondary-color;
  border: 1px solid $white;
  border-radius: 2px;
  overflow: hidden;
}
.lang-switcher__item {
  display: block;
  text-align: center;
  width: 100%;
  cursor: pointer;
  color: $white;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  padding: 5px;

  &:hover {
    background: rgba($white, 0.1);
  }
}
</style>
